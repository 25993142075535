<template>
  <div class="distributionHomePage">
    <div class="logoImg">
      <img class="imgleft" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/distributionHomePage/logo2.png" alt="">
      <img class="imgright" v-if="branchInfo.logoImg && $store.state.app.isWdBranch" :src="branchInfo.logoImg" alt="">
      <img v-if="!$store.state.app.isWdBranch" class="imgright" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/wdlogo.png" alt="" />
    </div>
    <div class="organization" v-if="token">
      <van-dropdown-menu class="branch" active-color="#415FF6">
        <van-dropdown-item v-model="value1" :options="branchList" @change="changeInstitution" />
      </van-dropdown-menu>
    </div>
    <div class="banner">
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/distributionHomePage/banner.png" alt="">
    </div>
    <div class="announcement" v-if="messageShow">
      <div class="label">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/distributionHomePage/tongzhi.png" alt="">
        <span class="title">通知公告</span>
        <span class="line"></span>
      </div>
      <div class="news" v-if="messageShow">
        <van-swipe style="height: 44px;" vertical :show-indicators="false" :autoplay="3000">
          <van-swipe-item v-for="(item, index) in messageList" :key="index">
            <div class="messageItem">
              <span class="name">{{item.userName}}</span>
              刚刚报名了《{{item.goodsName}}》
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="screen">
      <van-dropdown-menu active-color="#415FF6">
        <van-dropdown-item v-model="goodsListParams.zhiye" :options="trainingItemList" @change="trainingItemClick" />
        <van-dropdown-item v-model="goodsListParams.gongzhong" :options="workTypeList" @change="workTypeClick" />
        <van-dropdown-item v-model="goodsListParams.jineng" :options="skillLevelList" @change="skillLevelClick" />
        <van-dropdown-item v-model="goodsListParams.fangshi" :options="trainMethodList" @change="trainMethodClick" />
      </van-dropdown-menu>
    </div>
    <div class="dataList">
        <van-list
          v-if="list && list.length"
          class="msgList"
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
           finished-text=" 一 已经到底了，更多内容即将上线 一 "
          @load="onLoad"
        >
          <ul class="msg-list" v-if="list.length">
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="commodityDetails(item)"
            >
              <div class="box">
                  <div class="left">
                    <div class="cover">
                      <p class="trainMethod" v-if="item.trainingMethodName">{{item.trainingMethodName}}</p>
                      <img :src="item.pic" class="pic" alt="">
                    </div>
                  </div>
                  <div class="right">
                    <h2 class="textCut">{{item.name}}</h2>
                    <p class="time">
                      培训周期：
                      <span v-if="item.cycleType===1">{{item.trainingStartTime && item.trainingStartTime.split(' ')[0].replace(/-/g, '/')}} - {{item.trainingEndTime && item.trainingEndTime.split(' ')[0].replace(/-/g, '/')}}</span>
                      <span v-if="item.cycleType===2">随到随学</span>
                      <span v-if="item.cycleType===3">长期有效</span>
                    </p>
                    <p class="time" v-if="item.signUpStartTime">报名周期：
                      <span>{{item.signUpStartTime && item.signUpStartTime.split(' ')[0].replace(/-/g, '/')}} - {{item.signUpEndTime && item.signUpEndTime.split(' ')[0].replace(/-/g, '/')}}</span>
                    </p>
                    <p class="teacherTag">
                      <span class="classHour" v-if="item.schoolHour">{{item.schoolHour}}课时</span>
                      <template v-for="(el,index) in item.labelNameList">
                        <span class="classHour" :key="index">
                          {{el}}
                        </span>
                      </template>
                    </p>
                  </div>
              </div>
              
              <div class="bottom-box">
                <div class="bottom">
                  <div class="bottom-left">
                    <div v-if="$store.state.app.isWdBranch === true">
                      <!-- <p class="" v-if="item.isFree==1">免费</p>
                      <p class="" v-else><span>¥</span>{{item.price}}</p> -->
                    </div>
                  </div>
                  <div class="bottom-right">
                    <p :class="['btn', item.signUpExpired ? 'overdue' : '']" @click.stop="commodityDetails(item)">立即报名</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </van-list>
        <div class="empty" v-if="!list.length">
          <img src="./../../projectIntro/asset/images/empty-img.png" alt="">
          <p>暂无内容记录</p>
        </div>
    </div>
  </div>
</template>

<script>
import share from '../../../utils/share';
import axios from 'axios'
import config from '@/config/url'
export default {
  name: "distributionHomePage",
  data() {
    return {
       transLevel:{
                1:'01',
                2:'02',
                3:'03',
                4:'04',
                5:'05'
            },
      messageList: [],
      messageShow:true,
      value1: 0,
      trainingItemList: [
        {
          value: '',
          text: '培训项目',
        }
      ],
      workTypeList:[
        {
          value: '',
          text: '培训工种',
        }
      ],// 工种列表
      skillLevelList: [
        {
          value: '',
          text: '技能等级',
        }
      ],
      trainMethodList: [
        {
          value: '',
          text: '培训方式',
        }
      ],
      branchList: [],
      loading: false,
      finished: false,
      list: [
        
      ],
      paramsList: {
        pageNum: 1,
        pageSize: 10
      },
      goodsListParams:{
        branchId: '',// 机构id
        centerType:2,//1 服务商 2 渠道方
        fangshi:'',// 培训方式code
        gongzhong:'',// 培训工种
        jineng:'',// 技能等级
        sort:1,//排序规则 1 智能排序；2 最热排序；3 佣金
        zhiye: '',// 培训项目
        teacherFlag:2,
      },
      branchInfo: {
        // logoImg: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/distributionHomePage/logo2.png',
        logoImg: '',
        shortName: '',
      },
      token:'',
      screenWorkTypeList: [],
      screenSkillLevelList: [],
      branchId:"",
    };
  },
  async created() {
    await this.getLayoutSetting();
    this.token = localStorage.getItem('token')
    await this.findOrderUser();
    await this.findData();
     this.filterInfo();
    
    // 获取机构logo/简称
    await this.showBranchInfo()
    if(this.token){
        this.getFindUserBranchList()
    }
    
  },
  async mounted(){
    this.configObj = {
      desc:'家庭服务业（康养产业）职业技能培训服务平台',
      imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/logo2.png',
      title:'人力资源和社会保障部社会保障能力建设中心',
      link:window.location.origin+'/distributionHomePage'
    }
    share.getShare(this,this.configObj)
  },
  methods: {
    async getLayoutSetting(){
        await this.$api.rensheHome.configurations().then(res => {
            this.branchId = res.data.branchId;
          console.log(this.branchId,'this.branchId111')

        })
    },
    async getFindUserBranchList(){
        let branchRes = await this.$api.account.getFindUserBranchList({})
        if(branchRes.data && branchRes.data.length){
            this.branchList = branchRes.data.map((item) => {
                return {
                    text:item.name,
                    value:item.branch,
                    ...item
                }
            })
            let currentBranch = branchRes.data.find((item) => {
                return item.branch === localStorage.getItem('branch')
            })
            this.value1 = currentBranch && currentBranch.branch
        }
    },
    async showBranchInfo() {
       await this.$api.rensheUsercenter.showBranchInfo({ branchId: this.branchId }).then((res) => {
            if (res.data) {
                this.branchInfo = res.data
            }
        })
    },
    // 切换机构
    changeInstitution(value) {
    //   console.log('i', value)
      let selectedBranch = this.branchList.find(item => { 
          return item.value === value
      })
      const token = localStorage.getItem('token')
      const {organ,actor,branch} = selectedBranch
    // 获取切换机构的域名
    axios({
        url: config.API + '/modular/admin/v1/saasBranchDomain/0',
        headers: {
            token,
            organ,
            actor,
            branch,
        },
    }).then((res) => {
        let data = res.data
        if (data.success && data.data && data.data.length) {
            let domain = data.data.find((item) => {
                return item.branchDomain && item.branchDomain.includes('h5')
            })
            if (domain) {
                let protocol = 'https://'
                if (domain.branchDomain.includes('testing3')) {
                    protocol = 'http://'
                }
                domain = protocol + domain.branchDomain
                // console.log('domain--------------------', domain)
                window.location.href = `${domain}/distributionHomePage?token=${token}&organ=${organ}&actor=${actor}&branch=${branch}`
            }
        } else {
            this.$toast(data.data.message)
        }
    })

      // this.findOrderUser();
      // this.findData();
      // this.filterInfo();
    },
    /** 培训项目点击 item,index*/
    trainingItemClick(val){
      this.workTypeList = [
        {
          value: '',
          text: '培训工种',
        }
      ]
      this.skillLevelList = [
        {
          value: '',
          text: '技能等级',
        }
      ]
      this.goodsListParams.zhiye = val;
      if (val) {
        this.trainingItemList.forEach(ele=>{
          if (ele.value === val) {
            ele.gongzhong.forEach(ele=>{
              this.workTypeList.push({
                value: ele.vocationCode,
                text: ele.vocationName,
                dengji: ele.subVocations
              })
            })
          }
        })
      } else {
        this.workTypeList = this.workTypeList.concat(this.screenWorkTypeList);
      }
      this.skillLevelList = this.skillLevelList.concat(this.screenSkillLevelList);
      this.goodsListParams.gongzhong = '';
      this.goodsListParams.jineng = '';
      this.paramsList.pageNum = 1;
      this.findData();
    },
    /** 培训工种点击 */
    workTypeClick(val){
      this.skillLevelList = [
        {
          value: '',
          text: '技能等级',
        }
      ]
      this.goodsListParams.gongzhong = val;
      if (val) {
        this.workTypeList.forEach(ele=>{
          if (ele.value === val) {
            ele.dengji.forEach(ele=>{
              this.skillLevelList.push({
                value: ele.vocationCode,
                text: ele.vocationName
              })
            })
          }
        })
      } else {
        this.skillLevelList = this.skillLevelList.concat(this.screenSkillLevelList);
      }
      this.goodsListParams.jineng = '';
      this.paramsList.pageNum = 1;
      this.findData();
    },
    /** 技能等级 */
    skillLevelClick(val){
      this.goodsListParams.jineng = val;
      this.paramsList.pageNum = 1;
      this.findData();
    },
    /** 培训方式 */
    trainMethodClick(val){
      this.goodsListParams.fangshi = val;
      this.paramsList.pageNum = 1;
      this.findData();
    },
    async findOrderUser() {
      console.log(this.branchId,'this.branchId222222')
      await this.$api.rensheHome.findOrderUser(this.branchId).then(res => {
        if(res.data && res.data.length){
          this.messageShow = true;
          this.messageList = res.data;
        }else{
          this.messageShow = false;
        }
      }).catch(() => {
        this.messageShow = false;
      })
    },
    /**
     * 上拉加载
     */
    onLoad() {
        this.paramsList.pageNum = this.paramsList.pageNum*1 + 1
        this.findData('reachBottom')
    },
    async findData(type) {
      const data = {
        ...this.goodsListParams,
        branchId:this.branchId
      }
      const params = {
        ...this.paramsList
      }
      if(data.gongzhong && [1,2,3,4,5].includes(data.jineng)){
        data.jineng =data.gongzhong+'-'+this.transLevel[data.jineng]
      }
      await this.$api.rensheHome.distributionGoodsList(data,params).then(res => {
        if (res.data) {
          if (type === "reachBottom") {
              this.list.push(...res.data.list);
            } else {
                this.list = res.data.list || [];
                
            }
            // 加载状态结束
            this.loading = false;
            // 数据全部加载完成
            this.finished = res.data.isLastPage;

        }
      });
    },
    filterInfo() {
      const params = {
        branchId: this.branchId,
        centerType: 1,
        tagFlag:2
      }
      this.$api.rensheHome.filterInfo(params).then(res => {
        if(res.data){
          // 培训项目
          res.data.category.forEach(ele=>{
            this.trainingItemList.push({
              value: ele.vocationCode,
              text: ele.vocationName,
              gongzhong: ele.subVocations
            })
          })
          // 培训工种
          res.data.workType.forEach(ele=>{
            this.screenWorkTypeList.push({
              value: ele.vocationCode,
              text: ele.vocationName,
              dengji: ele.subVocations
            })
          })
          this.workTypeList = this.workTypeList.concat(this.screenWorkTypeList);
          // 技能等级
          res.data.level.forEach(ele=>{
            this.screenSkillLevelList.push({
              value: ele.vocationCode,
              text: ele.vocationName
            })
          })
          this.skillLevelList = this.skillLevelList.concat(this.screenSkillLevelList);
          // 培训方式
          res.data.label.forEach(ele=>{
            this.trainMethodList.push({
              value: ele.id,
              text: ele.name,
            })
          })
        }
      })
    },
    commodityDetails(item) {
      if (item.signUpExpired) {
        this.$toast('报名已过期')
      }
      this.$router.push({
        path:'/goodsDetail',
        query:{
          goodsKind:item.goodsKind,
          goodsId:item.id,
          fromType:1
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
@import "../asset/css/distributionHomePage.less";
</style>
<style lang="less">
.distributionHomePage {
  .van-dropdown-menu__bar {
    box-shadow: none;
    .van-dropdown-menu__item {
      .van-dropdown-menu__title {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .van-dropdown-item--down {
    .van-popup {
      position: absolute;
      overflow-y:scroll;
      .van-dropdown-item__option--active {
        background: #EFF1FE;
      }
    }
  }
  .organization {
    .van-dropdown-menu__bar {
      height: 50px;
      .van-dropdown-menu__item {
        justify-content: flex-start;
        .van-dropdown-menu__title {
          padding-left: 15px;
        }
      }
    }
  }
  .screen {
    .van-dropdown-menu__bar {
      height: 44px;
      padding: 0 20px 0 10px;
    }
  }
}
</style>

<template>
  <!-- eslint-disable -->
  <div class="homePage">
    <div class="logoTitle">
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/logo3.png" alt="">
      <span>人力资源和社会保障部社会保障能力建设中心</span>
    </div>
    <div class="bannerWrap">
      <div class="banner-text">
        <p class="pl-name1">人力资源和社会保障部社会保障能力建设中心</p>
        <p class="pl-name2">家庭服务业（康养产业）职业技能培训服务平台</p>
        <p class="pl-second">
          <span class="text-line">优质的教学资源、</span>
          <span>参培机构、服务</span>
        </p>
      </div>
      <img
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/bannerNoH5.png"
        alt="人力资源和社会保障部社会保障能力建设中心"
      >
    </div>

    <!-- 职业技能培训项目 -->
    <div class="vocationalSkills">
      <div class="title">
        职业技能培训项目
      </div>

      <div class="topThree">
        <div
          v-for="(item, index) in vocSkillList"
          :key="index"
          class="vocSkillItem"
          @click="handleJumpProjectIntroRoute(item)"
          :style="{'background-image': 'url('+item.imgUrl+')'}"
        >
          <div class="textWrap">
            <div class="itemTitle">
              {{ item.title }}
            </div>
            <div class="itemDesc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 专项技能培训项目 -->
    <div class="specialSkills">
      <div class="specialTitle">
        专项技能培训项目
      </div>

      <div class="topFour">
        <div
          v-for="(item, index ) in specialSkillList"
          :key="index"
          class="specialSkillItem"
          @click="handleJumpProjectIntroRoute(item)"
        >
          <div class="imgWrap">
            <img :src="item.imgUrl">
          </div>
          <div class="descWrap">
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 培训标准 -->
    <div class="trainingStandardWrap">
      <div class="trainingTop clearfix">
        <div class="title">培训标准</div>
        <div class="more">
          <router-link
            class="moreText"
            to="/trainStandard"
          >
            查看更多
          </router-link>
          <span class="moreIcon"></span>
        </div>
      </div>
      <ul class="trainList">
        <li
          v-for="(item, index) in trainStandardList"
          :key="item.id"
          class="trainListItem"
          @click="docPreview(item)"
        >
          <span class="serialNumber">
            0{{ index + 1 }}
          </span>
          <span class="trainItemTitle">
            {{ item.title }}
          </span>
        </li>
      </ul>
    </div>

    <!-- 证书展示 -->
    <div class="certWrap">
      <div class="certTitle">
        证书展示
      </div>
      <div class="certImgWrap">
        <img
          class="certImg"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/zs11.png"
        >
        <img
          class="certImg"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/zs22.png"
        >
        <img
          class="certImg"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/zs33.png"
        >
      </div>
    </div>

    <!-- 试听课 -->
    <div class="trialLesson">
      <div class="trialTitle">
        试听课
      </div>

      <div class="courseListBox">
        <ul class="courseList">
          <li class="courseItem" v-for="(item,index) in trialCourseContentList" :key="item.courseId">
            <div class="videoBox">
              <video
                class="video"
                :ref="'videoRef'+index"
                :id="'videoRef'+index"
                :src="item.videoUrl"
                :poster="item.posterUrl"
                webkit-playsinline='true'
                playsinline='true'
                @play="handleVideoStartPlay(item,index)"
                @pause="handleVideoPause(item)"
                @ended="handleVideoPlayEnded(item)"
              ></video>
              <!-- controls -->
              <!-- <transition name="el-fade-in">
                
              </transition> -->
              <!-- {{item.isPlaying}} -->
                <div
                  v-if="item.isPlaying"
                  class="playIcon playIcon2"
                  @click="handleVideoPlay(item,index)"
                >
		            	<svg-icon icon-class="play-icon" class-name="play-icon"></svg-icon>
                </div>
                <div
                  v-else
                  class="playIcon"
                  @click="handleVideoPlay(item,index)"
                >
		            	<svg-icon icon-class="play" class-name="play-icon"></svg-icon>
                </div>
                
              <div class="label">{{item.levelName}}</div>
              <div class="type">{{item.type}}</div>
            </div>
            <div class="conBox">
              <h3>{{item.videoName}}</h3>
              <p>{{item.courseDesc}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 合作机构入驻 -->
    <div class="institutionSettle">
      <div class="insTitle">合作机构入驻</div>
      <ul class="insList">
        <li class="insListItem">
          <div @click="handleJumpInstituteRoute(1)">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/hzjgrz1.png" alt="">
          </div>
          <p>如果你拥有课程资源，由此进入查看是否符合培训标准，申请成为教学合作机构即可获得利益。</p>
        </li>
        <li class="insListItem">
          <div @click="handleJumpInstituteRoute(2)">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/hzjgrz21.png" alt="">
          </div>
          <p>如果你的学员或员工需要获得优质培训服务，由此进入申请为参培机构即可获得。</p>
        </li>
      </ul>
    </div>
    <!-- 公告公示 -->
    <!-- <div class="affiche" @click="goPage">
      <span class="affiche-left">
        <svg-icon icon-class="affiche"></svg-icon>
      </span>
      <span class="affiche-right">查看平台相关公示信息</span>
      <span class="moreIcon"></span>
    </div> -->
    <van-popup v-model:show="popupShow2" @close="close2" class="vocation-box" position="bottom" :style="{ height: '76%' }">
      <p class="close-icon" @click="close2">
        <svg-icon icon-class="close"></svg-icon>
      </p>
      <div class="Vidpreview-container"  >
        <h2 class="name">{{work_name}}</h2>
        <div class="Vidpreview-box">
          <div id="Vidpreview" style="width:100%;height:500px;"></div>
        </div>
      </div>
      <div class="button" @click="close2">
        <p>已了解</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import share from '../../../utils/share';
import {
  vocSkillList,
  specialSkillList,
  trialCourseList,
} from "../common/data";
export default {
  data() {
    return {
      popupShow2:false,
      vocSkillList,
      work_name:'',
      specialSkillList,
      trainStandardList: [],
      trialCourseList,
      trialCourseContentList: [],
      // 试听课程内容索引
      trialCourseContentIndex: 0,
      auditionList: []
    };
  },
  created() {
    this.getStandardList();
    this.trialCourseList.forEach(ele=>{
      if (ele.children.length) {
        this.trialCourseContentList = this.trialCourseContentList.concat(ele.children);
      }
    })
  },
  mounted(){
    this.configObj = {
      desc:'家庭服务业（康养产业）职业技能培训服务平台',
      imgUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/logo2.png',
      title:'人力资源和社会保障部社会保障能力建设中心',
      link:window.location.origin+'/home'
    }
    share.getShare(this,this.configObj)
  },
  methods: {
    async getStandardList() {
      const res = await this.$api.rensheHome.getWorkUrl();

      if (res.success && res.data && res.data.length) {
        this.trainStandardList = res.data.slice(0, 5).map((item) => ({
          id: item.work_url,
          title: item.work_url_name,
        }));
      }
    },

    handleJumpProjectIntroRoute(item) {
      if (item.code) {
        this.$router.push({
          path: "projectIntro",
          query: {
            code: item.code,
            gongzhong:item.gongzhong,
            spec:item.spec
          },
        });
      } else {
        this.$toast("正在建设中，敬请期待…");
      }
    },
    // 公告公示
    goPage(){
      this.$router.push('/public')
    },
    close2(){
      this.work_name = "";
      let Vidpreview=document.querySelector('.web-office-default-container')
      if(Vidpreview){
        document.body.removeChild(Vidpreview)
      }
      this.popupShow2 = false
    },
    // 培训标准详情查看
    docPreview(item){
      if(!item.id){
        this.$toast('标准建设中');
        return false;
      }
      this.work_name = item.title;
      this.popupShow2 = true;
      const params = {
        resId: item.id
      }
      this.$api.projectIntro.getDocumentAccessToken({params}).then(res => {
        if (res.success) {
          // 下边是安全才有的
          this.$nextTick(() => {
            // eslint-disable-next-line no-undef
            let demo = aliyun.config({
              mount: document.querySelector('#Vidpreview'),
              url: res.data.previewURL, //设置文档查看URL地址。
            })
            // //设置AccessToken。
            demo.setToken({ token: res.data.accessToken })
          })
        } 
      })
    },

    handleVideoPlay(item,index) {
      if (item.isPlaying) {
         item.isPlaying = false;
        let ref = `videoRef${index}`;
        Reflect.ownKeys(this.$refs).forEach(key =>{
            if(key == ref){
              document.querySelector(`#${key}`).pause()
            }
        })
      }else {
        item.isPlaying = true;
        let ref = `videoRef${index}`;
        Reflect.ownKeys(this.$refs).forEach(key =>{
            if(key == ref){
              document.querySelector(`#${key}`).play()
            }
        })
      }
      
    },

    // 视频播放事件
    handleVideoStartPlay(item,index) {
      this.$nextTick(() => {
        let ref = `videoRef${index}`;
        Reflect.ownKeys(this.$refs).forEach(key =>{
          if(key != ref){
            document.querySelector(`#${key}`).pause()
          }
        })
      })

      item.isPlaying = true;
    },

    // 视频播放暂停
    handleVideoPause(item) {
      item.isPlaying = false;
    },

    handleVideoPlayEnded(item) {
      item.isPlaying = false;
    },

    handleJumpInstituteRoute(type) {
      if(type === 1){ // 教学
        this.$router.push("/resourceInstitutionsIn");
      }else { // 参培单位
        this.$router.push("/trainInstitutionsIn");
        
      }
    },
  },
};
</script>

<style lang="less">
@import "../asset/css/global.less";
*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}


*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
</style>
<style lang="less" scoped>
@import "../asset/css/index.less";
</style>
<style lang="less" scoped>
.bannerWrap{
  position:relative;
  .banner-text{
    position:absolute;
    left:78px;
    top:24px;
    height:480px;
    color:#fff;
    padding-top:44px;
    box-sizing:border-box;
    .pl-name1{
      font-size:24px;
      line-height:38px;
      width:400px;
      margin-bottom:16px;
    }
    .pl-name2{
      font-size:22px;
      line-height:36px;
      width:400px;
    }
    .pl-second{
      margin-top:24px;
      color:#CCDDFF;
      width:232px;
      line-height:30px;
      span{
        font-size:20px;
      }
    }
  }
}
.affiche{
  background: rgba(49,111,255,0.07);
  border-radius:16px;
  padding:28px 28px 28px 32px;
  height:126px;
  box-sizing:border-box;
  margin:0 30px 56px;
  display:flex;
  align-items:center;
  position: relative;
  .affiche-left{
    padding-right:32px;
    position: relative;
    &::after{
      position: absolute;
      content:'';
      background:#B5C3E1;
      width:1px;
      height:48px;
      right:0;
      top:50%;
      transform:translateY(-50%);
    }
    .icon{
      font-size:70px;
    }
  }
  .affiche-right{
    font-size:30px;
    color:#316FFF;
    padding-left:20px;
  }
  .moreIcon {
    display: inline-block;
    width: 13px;
    height: 22px;
    background: url(../asset/images/rightArrow.png);
    background-size: 100% 100%;
    vertical-align: middle;
    position: absolute;
    right:28px;
    top:50%;
    transform:translateY(-50%);
  }
}

</style>

const wx = window.wx;
// 微信分享
function getShare(that,configObj){
    
    var ourl =  window.location.href.split('#')[0]
    var shlink = configObj.link || window.location.href.split('#')[0];
    //获取微信appid
    that.$api.common.getShareConfig({url: ourl}).then(res => {
        if(res.success){
            //获取配置参数
            wx.config({
                debug: false,
                appId: res.data.appId,
                timestamp: res.data.timeStamp,
                nonceStr: res.data.nonce,
                signature: res.data.signature,
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                ],
            });
            wx.ready(function (){
                //分享朋友以及qq
                wx.updateAppMessageShareData({
                    title: configObj.title, // 分享标题
                    desc: configObj.desc, // 分享描述
                    link: shlink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: configObj.imgUrl, // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                        console.log('成功')
                    }
                });
                //分享朋友圈以及空间
                wx.updateTimelineShareData({
                    title: configObj.title, // 分享标题
                    link: shlink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: configObj.imgUrl, // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                        console.log('成功')
                        // $.post("$ctx/activity/shareCount.do",{"unionid":"$!unionid"},function(){});
                    }
                })
            });
        }
    })
}
export default {
    getShare
}
